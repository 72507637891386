.cv {
    width: 50em;
    overflow: hidden;
    position: relative;
}

.left-bar {
    height: 100%;
    position: absolute;
    background-color: rgb(0, 29, 92);
    width: 17em;
}

.left-bar-title {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
}

.experiences {
    margin-top: 40px;
    margin-left: 17em;
    width: 30em;
}

.experiences-title {
    margin-left: 20px;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
}

.name {
    width: 100%;
    text-align: center;
    font-size: 30px;
    color: white;
    margin-bottom: 5px;
}

.title {
    margin: 0px;
    width: 100%;
    text-align: center;
    color: lightgray;
    font-style: italic;
    font-size: 15px;
}

.profile-pic {
    margin: auto;
    margin-top: 20px;
    margin-left: 78px;
    width: 150px;
    height: 150px;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.left-bar-download-box {
    margin: auto;
    width: 150px;
    overflow: hidden;
    margin-top: 5px;
}

.left-bar-download-box-icon {
    float: left;
    width: 30px;
    height: 30px;
    margin-left: 20px;
}

.left-bar-download-box-button {
    float: left;
    text-decoration: none;
    margin-top: 7px;
    color: white;
}