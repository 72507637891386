.education {
    margin-left: 20px;
    margin-top: 25px;
}

.education-school-name {
    font-size: 12px;
    color: white;
    float: left;
    margin: 0px;
}

.education-date {
    font-size: 10px;
    color: white;
    float: right;
    margin: 0px;
    margin-top: 2px;
    margin-right: 5px;
}

.education-school-major {
    font-size: 10px;
    color: white;
    margin-top: 27px;
    margin-left: 5px;
}