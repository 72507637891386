.intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.intro-info {
    opacity: 0;
    transform: translateX(-50px);
    animation: slideIn 0.8s ease forwards;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}

.intro-logo {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
}

.intro-memoji {
    opacity: 0;
    transform: translateX(50px);
    animation: slideIn 0.8s ease forwards 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.intro-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    display: block;
}

.intro-details {
    margin-bottom: 1rem;
}

.intro-title {
    font-size: 3.5rem;
    font-weight: bold;
    margin: 0;
    color: rgb(231, 140, 69);
    transition: transform 0.3s ease;
}

.intro-title:hover {
    transform: scale(1.05);
}

.intro-subtitle {
    font-size: 1.8rem;
    opacity: 0.9;
    margin: 0.5rem 0;
}

.intro-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    width: 100%;
    max-width: 320px;
}

.intro-button {
    transition: all 0.3s ease;
    background: transparent;
    border: 2px solid rgb(231, 140, 69);
    border-radius: 25px;
    flex: 1;
}

.intro-button a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-decoration: none;
    color: rgb(231, 140, 69);
    padding: 0.8rem 1.5rem;
    white-space: nowrap;
}

.intro-button:hover {
    background: rgba(231, 140, 69, 0.1);
    transform: translateY(-3px);
}

.intro-button-icon {
    width: 20px;
    height: 20px;
    filter: invert(60%) sepia(87%) saturate(392%) hue-rotate(335deg) brightness(89%) contrast(92%);
}

.intro-button-text {
    margin: 0;
    font-weight: 500;
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}