.contact-me {
    padding: 4rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-me-box {
    max-width: 800px;
    width: 100%;
    background: rgba(231, 140, 69, 0.03);
    border-radius: 20px;
    padding: 3rem;
    box-shadow: 0 10px 30px rgba(231, 140, 69, 0.1);
    border: 2px solid rgba(231, 140, 69, 0.1);
}

.contact-me-method {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    padding: 1rem;
    border-radius: 12px;
    transition: all 0.3s ease;
    background: rgba(231, 140, 69, 0.05);
}

.contact-me-method:hover {
    transform: translateX(10px);
    background: rgba(231, 140, 69, 0.1);
}

.contact-me-method-icon {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    opacity: 0.8;
}

.contact-me-method-title {
    color: inherit;
    text-decoration: none;
    font-size: 1.1rem;
}

@keyframes fadeUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.contact-me-method a {
    text-decoration: none;
    color: currentColor;
}