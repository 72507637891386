.left-bar-list {
    margin-left: 20px;
    margin-top: 25px;
}

.left-bar-list-list {
    margin: 0px;
    padding-left: 20px;
}

.left-bar-list-list-item {
    margin: 0px;
    padding: 0px;
    color: white;
    font-size: 13px;
}