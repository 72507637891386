.github-projects-mobile {
    padding: 2rem 1.5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.github-projects-title-mobile {
    font-size: 2rem;
    color: rgb(231, 140, 69);
    margin-bottom: 2rem;
    text-align: center;
    position: relative;
    display: inline-block;
    align-self: center;
}

.github-projects-title-mobile::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 25%;
    width: 50%;
    height: 3px;
    background: rgb(231, 140, 69);
    border-radius: 2px;
}

.github-projects-container-mobile {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.github-project-mobile {
    background: rgba(231, 140, 69, 0.03);
    border-radius: 15px;
    border: 2px solid rgba(231, 140, 69, 0.1);
    overflow: hidden;
    animation: fadeIn 0.5s ease forwards;
    opacity: 0;
    transform: translateY(20px);
}

.github-project-content-mobile {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.github-project-title-mobile {
    font-size: 1.3rem;
    color: rgb(231, 140, 69);
    margin: 0;
    font-weight: 600;
}

.github-project-description-mobile {
    font-size: 0.95rem;
    line-height: 1.6;
    opacity: 0.9;
    margin: 0;
}

.github-project-tags-mobile {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.github-project-tag-mobile {
    background: rgba(231, 140, 69, 0.1);
    color: rgb(231, 140, 69);
    padding: 0.3rem 0.8rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
}

.github-project-link-mobile {
    color: rgb(231, 140, 69);
    text-decoration: none;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem;
    border: 2px solid rgb(231, 140, 69);
    border-radius: 12px;
    transition: all 0.3s ease;
}

.github-project-link-mobile:active {
    background: rgba(231, 140, 69, 0.1);
    transform: scale(0.98);
}

.github-project-mobile:nth-child(1) { animation-delay: 0.1s; }
.github-project-mobile:nth-child(2) { animation-delay: 0.2s; }
.github-project-mobile:nth-child(3) { animation-delay: 0.3s; }
.github-project-mobile:nth-child(4) { animation-delay: 0.4s; }

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Dark mode specific styles */
@media (prefers-color-scheme: dark) {
    .github-project-mobile {
        background: rgba(231, 140, 69, 0.05);
    }
}