.profile-link {
    width: 100%;
    height: 16px;
}

.profile-link-image {
    width: 16px;
    height: 16px;
    margin: 0px;
    float: left;
}

.profile-link-link {
    margin-left: 4px;
    float: left;
    color: white;
    font-size: 12px;
}