body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #1a1a1a;
    color: #f0f0f0;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: #f8f8f8;
    color: #333;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(231, 140, 69, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgb(231, 140, 69);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(231, 140, 69, 0.8);
}

::selection {
  background: rgba(231, 140, 69, 0.2);
  color: rgb(231, 140, 69);
}