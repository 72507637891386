.about-me-mobile {
    padding: 2rem 1.5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-me-image-box-mobile {
    margin-bottom: 2rem;
}

.about-me-image-mobile {
    width: 100%;
    max-width: 250px;
    height: auto;
    border-radius: 20px;
    margin: 0 auto;
    display: block;
    box-shadow: 0 10px 30px rgba(231, 140, 69, 0.15);
    border: 3px solid rgb(231, 140, 69);
}

.about-me-info-mobile {
    text-align: center;
}

.about-me-info-title-mobile {
    font-size: 1.8rem;
    color: rgb(231, 140, 69);
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;
}

.about-me-info-title-mobile::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 25%;
    width: 50%;
    height: 3px;
    background: rgb(231, 140, 69);
    border-radius: 2px;
}

.about-me-info-details-mobile {
    font-size: 1rem;
    line-height: 1.8;
    text-align: left;
    padding: 0 0.5rem;
}

.about-me-info-details-mobile i {
    color: rgb(231, 140, 69);
    font-weight: 500;
}