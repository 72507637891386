.about-me {
    padding: 4rem;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 4rem;
    align-items: center;
    min-height: 100vh;
}

.about-me-image {
    width: 100%;
    max-width: 400px;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(231, 140, 69, 0.15);
    border: 3px solid rgb(231, 140, 69);
    transition: transform 0.3s ease;
}

.about-me-image:hover {
    transform: scale(1.02);
}

.about-me-info {
    opacity: 0;
    transform: translateY(30px);
    animation: fadeUp 0.8s ease forwards;
}

.about-me-info-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: rgb(231, 140, 69);
}

.about-me-info-details {
    font-size: 1.1rem;
    line-height: 1.8;
    opacity: 0.9;
}

.about-me-info-details i {
    color: rgb(231, 140, 69);
    font-weight: 500;
}

@keyframes fadeUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}