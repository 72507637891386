.contact-me-mobile {
    padding: 2rem 1.5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-me-box-mobile {
    background: rgba(231, 140, 69, 0.05);
    border-radius: 20px;
    padding: 2rem 1.5rem;
    border: 2px solid rgba(231, 140, 69, 0.2);
}

.contact-me-title-box-mobile {
    text-align: center;
    margin-bottom: 2rem;
}

.contact-me-title-mobile {
    font-size: 1.8rem;
    color: rgb(231, 140, 69);
    margin: 0;
    position: relative;
    display: inline-block;
}

.contact-me-title-mobile::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 25%;
    width: 50%;
    height: 3px;
    background: rgb(231, 140, 69);
    border-radius: 2px;
}

.contact-me-methods-box-mobile {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-me-method-mobile {
    display: flex;
    align-items: center;
    padding: 1rem;
    background: rgba(231, 140, 69, 0.1);
    border-radius: 15px;
    transition: all 0.3s ease;
}

.contact-me-method-icon-mobile {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
}

.contact-me-method-title-mobile {
    color: inherit;
    text-decoration: none;
    font-size: 0.9rem;
    line-height: 1.4;
    word-break: break-word;
}