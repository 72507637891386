.experience {
    margin-left: 15px;
    margin-bottom: 15px;
    float: left;
    width: 100%;
}

.experience-header {
    height: 30px;
    margin-bottom: 0px;
}

.experience-logo {
    width: 30px;
    height: 30px;
    float: left;
    margin-bottom: 0px;
    margin-right: 5px;
}

.experience-title {
    float: left;
    padding-top: 4px;
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
    color: currentColor;
}

.experience-subtitle {
    margin-top: 10px;
    margin-left: 15px;
    height: 20px;
    margin-bottom: 0px;
}

.experience-position {
    color: gray;
    margin-top: 0px;
    float: left;
    font-style: italic;
    margin-bottom: 0px;
}

.experience-date {
    color: gray;
    margin-top: 0px;
    float: right;
    font-style: italic;
    margin-bottom: 0px;
}

.experience-list {
    margin-top: 10px;
    margin-left: 0px;
    padding-left: 40px;
}

.experience-list-item {
    margin-bottom: 6px;
    line-height: 20px;
    text-align: justify;
    text-justify: inter-word;
}