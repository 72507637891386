.intro-mobile {
    padding: 2rem 1.5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.intro-memoji-mobile {
    width: 100%;
    max-width: 200px;
    margin: 0 auto 2rem;
    position: relative;
    animation: float 3s ease-in-out infinite;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.intro-image-mobile {
    width: 100%;
    height: auto;
    display: block;
}

.intro-info-mobile {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.intro-logo-mobile {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
}

.intro-details-mobile {
    margin-bottom: 1.5rem;
}

.intro-title-mobile {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    color: rgb(231, 140, 69);
}

.intro-subtitle-mobile {
    font-size: 1.1rem;
    opacity: 0.9;
    margin: 0.5rem 0;
}

.intro-buttons-mobile {
    display: flex;
    gap: 1rem;
    width: 100%;
    max-width: 300px;
    justify-content: center;
    margin-top: 1rem;
}

.intro-button-mobile {
    flex: 1;
    transition: all 0.3s ease;
    background: transparent;
    border: 2px solid rgb(231, 140, 69);
    border-radius: 25px;
}

.intro-button-mobile a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-decoration: none;
    color: rgb(231, 140, 69);
    padding: 0.8rem 1.5rem;
    white-space: nowrap;
}

.intro-button-mobile:active {
    background: rgba(231, 140, 69, 0.1);
    transform: scale(0.98);
}

.intro-button-icon-mobile {
    width: 20px;
    height: 20px;
    filter: invert(60%) sepia(87%) saturate(392%) hue-rotate(335deg) brightness(89%) contrast(92%);
}

.intro-button-text-mobile {
    margin: 0;
    font-weight: 500;
}

@keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}