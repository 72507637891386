.professional-profile {
    margin-top: 25px;
    margin-left: 20px;
}

.professional-profile-title {
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
}

.professional-profile-text {
    color: white;
    font-size: 13px;
    margin-right: 10px;
    text-align: justify;
    text-justify: inter-word;
}