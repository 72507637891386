.main-experience-mobile {
    padding: 2rem 1.5rem;
    min-height: 100vh;
}

.main-experience-box-mobile {
    background: rgba(231, 140, 69, 0.05);
    border-radius: 20px;
    padding: 1.5rem;
    border: 2px solid rgba(231, 140, 69, 0.2);
}

.main-experience-banner-mobile {
    display: flex;
    overflow-x: auto;
    padding-bottom: 1rem;
    gap: 0.8rem;
    margin-bottom: 1.5rem;
    -webkit-overflow-scrolling: touch;
}

.main-experience-banner-company-name-mobile {
    padding: 0.6rem 1.2rem;
    background: rgba(231, 140, 69, 0.1);
    border-radius: 20px;
    white-space: nowrap;
    font-size: 0.9rem;
    border: 1px solid transparent;
}

.main-experience-banner-company-name-mobile.active {
    background: rgb(231, 140, 69);
    color: white;
    box-shadow: 0 4px 15px rgba(231, 140, 69, 0.2);
}

.main-experience-banner-info-position-mobile {
    font-size: 1.3rem;
    color: rgb(231, 140, 69);
    margin-bottom: 1rem;
    font-weight: 600;
}

.main-experience-banner-info-detail-mobile {
    font-size: 0.95rem;
    line-height: 1.6;
    margin-bottom: 0.8rem;
    padding-left: 1.5rem;
    position: relative;
    list-style: none;
}

.main-experience-banner-info-detail-mobile::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.8rem;
    width: 6px;
    height: 6px;
    background: rgb(231, 140, 69);
    border-radius: 50%;
}