@media (prefers-color-scheme: dark) {
    body {
        background-color: #1a1a1a;
        color: #f0f0f0;
    }
}

@media (prefers-color-scheme: light) {
    body {
        background-color: #f8f8f8;
        color: #333;
    }
}

.main {
    min-height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
}

.home-section {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
}

.section-title {
    font-size: 2.5rem;
    color: rgb(231, 140, 69);
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
}

.section-title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60%;
    height: 3px;
    background: rgb(231, 140, 69);
    border-radius: 2px;
}