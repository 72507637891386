.main-experience {
    padding: 4rem;
    min-height: 100vh;
}

.main-experience-box {
    max-width: 1200px;
    margin: 0 auto;
    background: rgba(231, 140, 69, 0.03);
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(231, 140, 69, 0.1);
    border: 2px solid rgba(231, 140, 69, 0.1);
}

.main-experience-banner {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    overflow-x: auto;
    padding-bottom: 1rem;
}

.main-experience-banner-company-name {
    padding: 0.8rem 1.5rem;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: rgba(231, 140, 69, 0.1);
    border: 1px solid transparent;
}

.main-experience-banner-company-name.active {
    background: rgb(231, 140, 69);
    color: white;
    transform: scale(1.05);
}

.main-experience-banner-info {
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
}

.main-experience-banner-info-position-title {
    font-size: 1.5rem;
    color: rgb(231, 140, 69);
    margin-bottom: 1.5rem;
}

.main-experience-banner-info-detail {
    margin: 0.8rem 0;
    padding-left: 1.5rem;
    position: relative;
    line-height: 1.6;
    list-style: none;
}

.main-experience-banner-info-detail::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.8rem;
    width: 6px;
    height: 6px;
    background: rgb(231, 140, 69);
    border-radius: 50%;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Scrollbar styling */
.main-experience-banner::-webkit-scrollbar {
    height: 6px;
}

.main-experience-banner::-webkit-scrollbar-track {
    background: rgba(255, 107, 0, 0.1);
    border-radius: 3px;
}

.main-experience-banner::-webkit-scrollbar-thumb {
    background: #FF6B00;
    border-radius: 3px;
}