.github-projects {
    padding: 4rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.github-projects-title {
    font-size: 2.5rem;
    color: rgb(231, 140, 69);
    margin-bottom: 3rem;
    text-align: center;
    position: relative;
    display: inline-block;
    align-self: center;
}

.github-projects-title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 25%;
    width: 50%;
    height: 3px;
    background: rgb(231, 140, 69);
    border-radius: 2px;
}

.github-projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.github-project {
    background: rgba(231, 140, 69, 0.03);
    border-radius: 15px;
    border: 2px solid rgba(231, 140, 69, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
}

.github-project:hover {
    transform: translateY(-5px);
    border-color: rgb(231, 140, 69);
    box-shadow: 0 10px 20px rgba(231, 140, 69, 0.1);
}

.github-project-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.github-project-title {
    font-size: 1.3rem;
    color: rgb(231, 140, 69);
    margin: 0 0 1rem 0;
    font-weight: 600;
}

.github-project-description {
    font-size: 0.95rem;
    line-height: 1.6;
    opacity: 0.9;
    margin: 0 0 1.5rem 0;
    flex-grow: 1;
}

.github-project-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.github-project-tag {
    background: rgba(231, 140, 69, 0.1);
    color: rgb(231, 140, 69);
    padding: 0.3rem 0.8rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
}

.github-project-link {
    color: rgb(231, 140, 69);
    text-decoration: none;
    font-weight: 500;
    padding: 0.8rem;
    border: 2px solid rgb(231, 140, 69);
    border-radius: 12px;
    text-align: center;
    transition: all 0.3s ease;
}

.github-project-link:hover {
    background: rgba(231, 140, 69, 0.1);
}